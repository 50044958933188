<template>
  <div class="lg:w-auto lg:px-0 lg:py-2">
    <input
    :maxlength="max"
      class="w-full shadow rounded-md border-2 border-white border-b border-b-[#8CBAFF] pl-2 py-2 placeholder:text-left placeholder:text-[#4F4F4F] placeholder:font-normal placeholder:text-[16px] font-[Roboto]"
      :type="type" :placeholder="placeholder" :pattern="pattern" :class="{
        'border-b-red-400': (!isValidRegex && modelValue),
        'focus:outline-none focus:ring focus:ring-green-400 placeholder:text-[#9CA3AF]': whatsApp
      }" :autocomplete="autocomplete" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
      :name="name" />
    <p class="mt-2 text-[10px] text-red-400" v-if="!isValidRegex && modelValue">
      <span v-if="hint === ''">Corrija o valor que escreveu de acordo com a dica se houver, ele não
        esta válido.</span>
      <span v-else>{{ hint }}</span>
    </p>
  </div>
</template>

<script>
export default {
  watch: {
    modelValue(value) {
      const regex = new RegExp(this.regex);
      this.$emit("update:isValid", regex.test(value));
    },
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    pattern: {
      type: String
    },
    max: {
      type: String,
    },
    hint: {
      type: String,
      default: null,
    },
    autocomplete: {
      // This article talks about the nope option:
      // https://medium.com/nerd-for-tech/turn-off-autocomplete-in-modern-browsers-e7829bdfa0a3
      type: String,
      default: "nope",
    },
    regex: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "text",
      type: String,
    },
    whatsApp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update:isValid"],
  computed: {
    isValidRegex() {
      if (this.regex !== null && this.modelValue !== '') {
        const regex = new RegExp(this.regex);

        return regex.test(this.modelValue);
      }
      return true;
    },
  },
};
</script>
<style>
.required::-webkit-input-placeholder:after {
    content: " *";
    color: red;
}

.required:-moz-placeholder:after {
    content: " *";
    color: red;  
}

.required:-ms-input-placeholder:after {  
    content: " *";
    color: red;  
}</style>