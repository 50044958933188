<template>
  <a class="cursor-pointer" v-if="$route.name !== 'empresas-id'" @click="showChat = !showChat" target="_blank">
    <div class="fixed right-0 z-50 bottom-0 mb-20 mr-10">
      <div class="w-20 h-20 rounded-full flex justify-center items-center bg-[#03D342]">
        <img src="@/assets/img/home/help/whatsapp.svg"
          alt="Icone do Whats App, clique para enviar mensagens via Whats App" />
      </div>
    </div>
  </a>

  <div v-if="showChat"
    class="fixed bottom-44 md:right-16 right-6 z-50 bg-white border border-gray-300 rounded-xl shadow-2xl w-80 max-w-[90vw]">
    <div class="flex justify-between items-center p-4 bg-green-500 text-white rounded-t-xl">
      <div class="flex gap-2 items-center">
        <img class="lg:mt-2 w-[30px] md:h-[35px] md:w-[36px] lg:h-[32px] lg:w-[31px] ml-0"
          src="@/assets/img/common/logo.svg" alt="Logo BCompliance" />
        <div>
          <div class="router-link-active">BCompliance&amp;Law</div>
          <div class="text-sm opacity-80 text-left text-[10px]">Online</div>
        </div>
      </div>
      <button @click="showChat = !showChat" aria-label="Fechar chat">✕</button>
    </div>
    <div class="text-left text-sm p-4 text-sm text-gray-700">
      <p class="mb-3 font-bold">Olá, vamos conversar pelo whatsapp?</p>
      <p class="mb-3">
        Para agilizar seu atendimento, preencha os campos abaixo e clique em
        iniciar conversa. 😀
      </p>

      <input v-model="name" placeholder="Nome*"
        class="w-full mb-2 p-2 border rounded resize-none focus:outline-none focus:ring focus:ring-green-400 border-b border-b-[#8CBAFF]" />

      <div class="flex">
        <div>
          <CountriesPhoneCode v-model="countryCode" />
        </div>
        <div class="grow">
          <InputComponent class="pl-2 pt-2" @input="formatPhone" v-model="phone" max="15" type="text" :whats-app="true"
            placeholder="Telefone*" hint="Insira um telefone válido (DDD + Número). Exp: 15998341602" name="tel"
            autocomplete="tel" :required="true" />
        </div>
      </div>

      <input v-model="email" placeholder="E-mail"
        class="w-full mb-2 p-2 border rounded resize-none focus:outline-none focus:ring focus:ring-green-400 border-b border-b-[#8CBAFF]" />

      <div class="text-[9px] text-gray-500">
        <p class="text-xs text-gray-500">
          * Campos obrigatórios
        </p>
      </div>

      <button @click="sendMessage" class="mt-3 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full">
        Enviar mensagem no WhatsApp
      </button>
    </div>
  </div>
</template>
<script>
import CountriesPhoneCode from "@/components/CountriesPhoneCode.vue";
import InputComponent from "@/components/InputComponent.vue";
export default {
  components: {
    CountriesPhoneCode,
    InputComponent,
  },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      showChat: false,
      countryCode: "+55",
    };
  },
  methods: {
    async sendMessage() {
      if (!this.name.trim() || !this.phone.trim()) {
        alert("Por favor, preencha os campos obrigatórios: Nome e Telefone.");
        return;
      }

      await this.axios.post("public/schedule", {
        name: this.name,
        email: this.email,
        phone: this.countryCode + this.phone,
        tag: "whatsapp",
      });

      this.generateWhatsAppLink(
        '5515998341602',
        `Olá, meu nome é ${this.name}. Estou interessado em saber mais sobre os serviços da BCompliance&Law.`
      );
    },
    generateWhatsAppLink(phoneNumber, message) {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      // Preprocess the message
      try {
        message = message.replaceAll("%", "%25");
        message = encodeURIComponent(decodeURI(message));
      } catch (e) {
        message = encodeURIComponent(message);
      }

      let link = "";

      if (isMobile) {
        link = `https://wa.me/${phoneNumber}?text=${message}`;
      } else {
        link = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
      }

      window.open(link, "_blank", "noopener");
      this.conversion();
    },
    formatPhone(input) {
      this.phone = input.target.value.replace(/\D/g, "");

      this.phone = this.phone.replace(/^\+55|^55/, "");

      if (this.phone.length === 11) {
        this.phone = this.phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      }

      else if (this.phone.length === 10) {
        this.phone = this.phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      }
    },
    conversion() {
      if (process.env.VUE_APP_ENV === "production") {
        window.gtag("event", "conversion", {
          send_to: "AW-11020125432/hwoPCKLHyooYEPiJ54Yp",
        });
      }
    },
  },
};
</script>
