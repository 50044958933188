<template>
    <div class="w-full shadow rounded-md border-2 border-white border-b border-b-[#8CBAFF] pl-2 py-2 placeholder:text-left
        placeholder:text-[#4F4F4F] placeholder:font-normal placeholder:text-[16px] font-[Roboto] mr-2 mt-2">
        <select @input="$emit('update:modelValue', $event.target.value)">
            <option v-for="country of countries" :key="country.code" :value="country.ddi" :selected="country.code === 'BR'"
                title="${country.name}">
                {{ `${getFlagEmoji(country.code)} ${country.ddi}` }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    data() {
        return {
            "countries": [
                {
                    "code": "US",
                    "ddi": "+1",
                    "name": "Estados Unidos"
                },
                {
                    "code": "GB",
                    "ddi": "+44",
                    "name": "Reino Unido"
                },
                {
                    "code": "CA",
                    "ddi": "+1",
                    "name": "Canadá"
                },
                {
                    "code": "AU",
                    "ddi": "+61",
                    "name": "Austrália"
                },
                {
                    "code": "IN",
                    "ddi": "+91",
                    "name": "Índia"
                },
                {
                    "code": "ES",
                    "ddi": "+34",
                    "name": "Espanha"
                },
                {
                    "code": "MX",
                    "ddi": "+52",
                    "name": "México"
                },
                {
                    "code": "AR",
                    "ddi": "+54",
                    "name": "Argentina"
                },
                {
                    "code": "CO",
                    "ddi": "+57",
                    "name": "Colômbia"
                },
                {
                    "code": "PE",
                    "ddi": "+51",
                    "name": "Peru"
                },
                {
                    "code": "CN",
                    "ddi": "+86",
                    "name": "China"
                },
                {
                    "code": "TW",
                    "ddi": "+886",
                    "name": "Taiwan"
                },
                {
                    "code": "SG",
                    "ddi": "+65",
                    "name": "Singapura"
                },
                {
                    "code": "MY",
                    "ddi": "+60",
                    "name": "Malásia"
                },
                {
                    "code": "HK",
                    "ddi": "+852",
                    "name": "Hong Kong"
                },
                {
                    "code": "BR",
                    "ddi": "+55",
                    "name": "Brasil"
                },
                {
                    "code": "PT",
                    "ddi": "+351",
                    "name": "Portugal"
                },
                {
                    "code": "AO",
                    "ddi": "+244",
                    "name": "Angola"
                },
                {
                    "code": "MZ",
                    "ddi": "+258",
                    "name": "Moçambique"
                },
                {
                    "code": "CV",
                    "ddi": "+238",
                    "name": "Cabo Verde"
                },
                {
                    "code": "RU",
                    "ddi": "+7",
                    "name": "Rússia"
                },
                {
                    "code": "KZ",
                    "ddi": "+7",
                    "name": "Cazaquistão"
                },
                {
                    "code": "UA",
                    "ddi": "+380",
                    "name": "Ucrânia"
                },
                {
                    "code": "BY",
                    "ddi": "+375",
                    "name": "Bielo-Rússia"
                },
                {
                    "code": "UZ",
                    "ddi": "+998",
                    "name": "Uzbequistão"
                },
                {
                    "code": "JP",
                    "ddi": "+81",
                    "name": "Japão"
                },
                {
                    "code": "EG",
                    "ddi": "+20",
                    "name": "Egito"
                },
                {
                    "code": "MA",
                    "ddi": "+212",
                    "name": "Marrocos"
                },
                {
                    "code": "SA",
                    "ddi": "+966",
                    "name": "Arábia Saudita"
                },
                {
                    "code": "DZ",
                    "ddi": "+213",
                    "name": "Argélia"
                },
                {
                    "code": "NP",
                    "ddi": "+977",
                    "name": "Nepal"
                },
                {
                    "code": "FJ",
                    "ddi": "+679",
                    "name": "Fiji"
                }
                // Adicione mais países aqui
            ]

        }
    },
    methods: {
        getFlagEmoji(code) {
            const codePoints = code
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        }
    }
}
</script>